<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Bulk Update Company Rates</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading">
        <v-form class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="selectedCompanies"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Select Companies"
                  multiple
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-btn
                  block
                  outlined
                  :loading="loading"
                  @click="selectAllCompanies"
                  >Select all</v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-text-field
                  outlined
                  v-model="percentage"
                  dense
                  type="number"
                  suffix="%"
                  label="Percentage"
                  ></v-text-field
                >
              </v-col>
              <v-col cols="4" v-if="selectedCompanies.length > 0 && percentage">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="bulkUpdateCompanyRates()"
                  >{{ rateChange }} rates for selected companies</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>

      <v-snackbar v-model="snackbar" :timeout="5000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import CompaniesUtil from "@/services/CompaniesUtil.js";
import BillingSnap from "@/services/BillingSnap.js";

export default {
  data() {
    return {
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      selectedCompanies: [],
      companies: [],
      percentage: 0,
    };
  },
  created() {
    this.getCompaniesData();
  },
  computed:{
    rateChange(){
         return this.percentage < 0 ? 'decrease ' : 'increase '
    }
  },
  methods: {
    selectAllCompanies() {
      // Copy all v-select's items in your selectedItem array
      this.selectedCompanies = this.companies.filter(
        (comp) => comp.code !== "3PL" && comp.code !== "V08"
      );
    },

    // Company Data
    getCompaniesData() {
      // Use the BillingSnap to call the getBillingData() method
      return CompaniesUtil.getSnapCompanies()
        .then((companies) => {
          this.companies = companies;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },

    bulkUpdateCompanyRates() {
      this.loading = true;
      let percentage = this.percentage / 100;
      let reqBody = {
        percentage: percentage,
        company_ids: this.selectedCompanies,
      };
      BillingSnap.bulkUpdateCompanyRates(reqBody)
        .then((response) => {
          this.text = `${response}`;
        })
        .catch((error) => {
          this.text = `${error.message}`;
        })
        .finally(() => ((this.loading = false), (this.snackbar = true)));
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}
</style>
